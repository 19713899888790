/*  */

[contenteditable] {
    caret-color: #28FE14;
}
/* 
.cursor {
    background: #28FE14;
    animation: blink 1.2s infinite;
    width: 7px;
    height: 0.9rem;
    display: inline-block;
    margin: auto 0px;
    position: relative;
    top: 2px;
}

.cursor-hidden {
    display: none !important;
    visibility: hidden;
    width: 0 !important;
}
.cursor-txtfollow {
    left: -5px;
    -webkit-animation: none;
    animation: none;
    opacity: 0.7;
}

@keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
} */