@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
   /* Sizes & borders */
   --menu-bar-height: 26px;
   --border: 2px solid #000;
   --border-dotted: 2px dotted #000;
   --border-small: 1.6px solid #000;

  /* Images */
  --quadri-pattern-url: url("../assets/images/quadri-pattern.png");

  /* Font and fluid typography*/
  --default-font: "chikarego2medium", monospace;
 
  /* Colors */
  --link-color: #000;
  --link-hover-color: #fff;
  --background-color: #f1f1f1;
  --menu-bar-color: #f8f9fa;
}

html,
body {
  padding: 0;
  margin: 0;

  /* Background */
  background-color: #cccccc;
  background-image: url("../assets/images/bg-pattern.png");
  background-repeat: repeat;
  background-size: 1rem;

  /* Default font */
  font-family: var(--default-font);
  text-decoration: none;
}

body {
  overflow: hidden;
}

.lined-bg {
  background-image: url("../assets/images/bg-lines.gif");
  background-repeat: repeat;

}

/* 
a {
  color: var(--link-color);
  text-decoration: none !important;
  background-color: transparent;
  line-height: normal;
  transition: background-color 200ms;
}

a:hover {
  color: var(--link-hover-color);
  background-color: #000;
  text-decoration: none !important;
}

a:focus {
  color: var(--link-hover-color);
  background-color: #000;
  text-decoration: none !important;
} */

.rounded-button {
  align-self: center;
  letter-spacing: 0.1rem;
  padding: 0.3rem 14px;
  background-color: #fff;
  border: 2px ridge #000;
  border-radius: 10px;
  
  color: #000;
}

.rounded-button:hover {
  background-image: url("../assets/images/quadri-pattern.png");
  /* color: #fff; */
  color: #000;
  transition: all;
  /* text-shadow: 1px 1px black, 1px 1px 5px black, 1px 1px 5px darkgray; */

  text-decoration-line: underline;
  text-decoration-color: #000 !important;
  text-decoration-style: dotted !important;
  box-shadow: 3px 3px black;
  
}

.rounded-button:hover span {
  background-color: #000 !important;
  color: #fff !important;
} 

.border-dotted-medium {
  border: 1.5px dotted #000 !important;
  border-bottom: none !important;
  width: 100% !important;
}

* {
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .optional-br {
    display: none;
  }
}
/*@media (prefers-color-scheme: dark) {*/
  /*html {*/
  /*  color-scheme: dark;*/
  /*}*/
  /*body {*/
  /*  color: white;*/
  /*  background: black;*/
  /*}*/
/*}*/

ul {
  list-style: none;
  margin: 0;
  padding: 0
}

@font-face {
  font-family: "chikarego2medium";
  src: url("../assets/fonts/chikarego2.woff2") format("woff2"),
       url("../assets/fonts/chikarego2.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "chicago";
  src: url("../assets/fonts/chicago.woff2") format("woff2"),
       url("../assets/fonts/chicago.woff") format("woff");
}

/* hr {
  border: 1.5px dotted #000;
  border-bottom: none;
  margin: 8px auto;
  opacity: 1 !important;
} */

/**********************

 * Layout *

**********************/


@media (max-width: 768px) {
  .window-small {
      top: calc(220px + 3%);
      height: 240px;
  }
  .window-small h1 {
      font-size: 2.2rem;
  }
  /* .homepage-content {
      padding: 15px 30px;
  } */
}

.no-margin {
  margin: 0;
}

/**********************

 * Scrollbar Styling *

**********************/

::-webkit-scrollbar {
  width: 20px;
  height: 20px;
  background: #fff;
  -webkit-box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 1);
  box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 1)
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
  background: #fff;
  -webkit-box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 1);
  box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 1)
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 2px solid #000000;
  border-radius: 0px;
  background-image: var(--quadri-pattern-url);
  opacity: 0.8;
}

::-webkit-scrollbar-thumb:hover {
  opacity: 1;
}

::-webkit-scrollbar-thumb:active {
  background: #000;
}

::-webkit-scrollbar-track {
  background: #fff;
  border: 1px solid #000;
  border-radius: 0px;
}

::-webkit-scrollbar-button:vertical:start:decrement {
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid #000;
  background: url("/public/assets/icons/window/arrow-up.svg") no-repeat top;
}

::-webkit-scrollbar-button:vertical:end:increment {
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid #000;
  background: url("/public/assets/icons/window/arrow-down.svg") no-repeat bottom;
}

::-webkit-scrollbar-button:horizontal:start:decrement {
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid #000;
  background: url("/public/assets/icons/window/arrow-left.svg") no-repeat left;
}

::-webkit-scrollbar-button:horizontal:end:increment {
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid #000;
  background: url("/public/assets/icons/window/arrow-right.svg") no-repeat right;
}

::-webkit-scrollbar-corner {
  background: #fff url("/public/assets/icons/window/resize.svg") no-repeat center;
  background-position: 3px 3px;
  background-size: 15px
}

::-webkit-resizer {
  background: #fff url("/public/assets/icons/window/resize.svg") no-repeat center;
  background-position: 3px 3px;
  background-size: 14px;
}

/**********************

   * Style Resets *

**********************/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:where(b, strong) {
  font-weight: bolder;
}

@supports not (min-block-size: 100dvb) {
  :where(html) {
    block-size: 100%;
  }
}

@media (prefers-reduced-motion: no-preference) {
  :where(html:focus-within) {
    scroll-behavior: smooth;
  }
}

:where(input, button, textarea, select) {
  font: inherit;
  color: inherit;
}

:where(textarea) {
  resize: vertical;
  resize: block;
}

:where(button, label, select, summary, [role='button'], [role='option']) {
  cursor: pointer;
}

:where(:disabled) {
  cursor: not-allowed;
}

:where(label:has(> input:disabled), label:has(+ input:disabled)) {
  cursor: not-allowed;
}

:where(ul, ol) {
  list-style: none;
}

/* :where(img, svg, video, canvas, audio, iframe, embed, object) {
  display: block;
}

:where(img, picture, svg) {
  max-inline-size: 100%;
  block-size: auto;
  border-style: none;
} */

:where(p, h1, h2, h3, h4, h5, h6) {
  overflow-wrap: break-word;
}

:where(h1, h2, h3) {
  line-height: calc(1em + 0.5rem);
}

.article-content {
  @apply max-w-3xl;
}

.article-content p:has(img) {
  @apply flex justify-center;
}

.article-content img {
  @apply md:px-7 my-6 md:my-7 max-w-full md:max-w-2xl items-center;
}

.article-content pre:has(code) {
  /* @apply flex justify-self-start text-left py-5 px-10; */
  /* background-color: aliceblue; */
}

.article-content code {
  @apply max-w-full md:max-w-2xl items-center;
}