.retro-clock {
  background: #f1f1f1;
  /* border: 2px solid #000; */
  border: 0.07rem ridge #000;

  border-radius: 50%;
  width: 7rem !important;
  height: 7rem !important;
  min-width: 7rem;
  position: relative;
  /* margin: 10px auto; */
}

.react-clock .react-clock__mark__body {
  /* background-color: #505050; */
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

}
.retro-clock .react-clock__hand__body {
  border-radius: 10px !important;
}

.retro-clock .react-clock__minute-hand__body {
  background-color: darkgray !important;
}

.retro-clock .react-clock__second-hand__body {
  background-color: #FF3B30 !important;
  top: 11% !important;
}

.retro-clock .react-clock__mark__number {
  font-size: 0.9rem;  
}

@media (max-width: 640px) {
  .retro-clock {
    width: 5.5rem !important;
    height: 5.5rem !important;
    min-width: 5.5rem;
  }

}